<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  labelKeyAdd="addNewEducationDoc"
  isCanMerge
).px-4
  FormAdd(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorEducationTable',
  components: {
    FormAdd: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'number_document', text: this.$t('number') },
        { value: 'date_issue_document', text: this.$t('dateIssue') },
        // { value: 'educationTypeDocument', text: this.$t('typeDoc') },
        { value: 'qualification', text: this.$t('qualification') },
        { value: 'speciality', text: `${this.$t('profession')} / ${this.$t('specialty')}` },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'education-documents-info', params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.education,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getEducationDocs']),
    async getData (params) {
      this.getEducationDocs({ ...this.$route.params, params })
    }

  }
}
</script>
